.landing_page {
  background-color: #fff8f5;
  color: #1a2f54;
  position: relative;
  .landing_header {
    position: sticky;
    top: 0;
    width: 100%;
    background-color: #fff8f5;
    transition: all 0.5s;
    .navbar {
      padding: 10px 0;
      .navbar-brand {
        img {
          width: 100px;
          transition: 0.5s;
        }
      }
      .nav-item {
        border-radius: 10px;
        .nav-link {
          padding: 5px 15px;
          color: #2c2c2c;
          font-weight: 500;
          font-size: 16px;
          transition: all 0.3s;
          text-transform: uppercase;
        }
        &:hover {
          background: #ffaba2;
          .nav-link {
            color: #fff;
            // font-weight: 600;
          }
        }
      }
    }
  }
  .navScroll {
    background-color: #d6c6bff2;
    z-index: 9999;
    .navbar {
      .navbar-brand {
        img {
          width: 60px;
          height: 40px;
          transition: 0.5s;
        }
      }
    }
  }
  .underline {
    background-color: #cc8557;
    height: 2px;
    width: 100px;
    margin: 10px auto 0;
  }
  .title_desc {
    color: #cc8557;
    max-width: 550px;
    text-align: center;
    margin: 30px auto 0;
  }
  // generic button
  .page_btn {
    font-size: 1rem;
    background-color: #cc815a;
    color: #fff;
    border-radius: 2px;
    padding: 7px 20px;
    display: inline-block;
  }
  // generic title
  .section_title {
    font-size: 2.5rem;
    font-weight: 400;
    color: #cc8557;
  }
  #hero_section {
    height: 85vh;
    background-image: url(/img/landing_img/hero_bg.jpg);
    background-position: center;
    background-size: cover;
    .hero_outter {
      position: relative;
      height: 85vh;
      h2 {
        position: absolute;
        font-weight: 300;
        font-size: 2rem;
        bottom: 5rem;
        text-transform: uppercase;
        background-color: #000000;
        color: #fff;
        padding: 10px 20px;
      }
    }
  }
  #about {
    overflow: hidden;
    .about_left {
      position: relative;
      img {
        position: relative;
        z-index: 10;
      }
      &::after {
        content: "";
        position: absolute;
        background-color: #cc815a;
        width: 18rem;
        height: 18rem;
        bottom: -4rem;
        right: -3.5rem;
        border-radius: 50%;
        z-index: 1;
      }
    }
    .about_right {
      .about_right_title {
        // transform: translateX(25%);
        h2 {
          position: relative;
          transform: translateX(-38%);
          width: 35rem;
          position: relative;
          z-index: 20;
          color: #c18460;
          background-color: #eee4da;
          padding: 3rem 3rem 3rem 3rem;
          font-weight: 600;
          font-size: 2rem;
        }
      }
    }
  }
  .services {
    background-image: url(/img/landing_img/service_bg.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    .service_inner {
      background-color: rgba($color: #fff, $alpha: 0.7);
      .services_inner {
        padding: 2.5rem 0 1.5rem;
        .single_service {
          p {
            text-align: start;
            margin-bottom: 13px;
            font-weight: 500;
            font-size: 16px;
          }
          img {
            border-radius: 15px;
            aspect-ratio: 15/10;
          }
        }
        .service_bottom {
          margin-top: 2.5rem;
          .next_prev_icons {
            svg {
              color: #c68469;
              font-size: 20px;
              cursor: pointer;
            }
            #prev_btn {
              margin-right: 20px;
            }
          }
          .swiper-button-disabled svg {
            color: gainsboro;
          }
        }
      }
    }
  }
  #about_bottom {
    .about_bottom_bg {
      position: relative;
      overflow: hidden;
      &::before {
        position: absolute;
        content: "";
        top: 0;
        left: 0;
        width: 100%;
        height: 200%;
        background-color: #eee4da;
        border-radius: 50% 50% 0 0;
      }
      p {
        color: #bfafa5;
        font-size: 17px;
        line-height: 1.8rem;
        font-weight: 500;
      }
    }
  }
}
#about_third {
  background-color: #cc815a;
  p {
    color: #bfafa5;
    font-size: 17px;
    line-height: 1.8rem;
    font-weight: 500;
  }
  .row {
    background-color: #fff;
    padding: 30px;
    .corner_img {
      height: 100%;
      object-fit: cover;
    }
    // .right_s {
    //   background-image: url(/img/landing_img/about_third_corner.png);
    // }
  }
  img {
    max-height: 400px;
    min-height: 350px;
    width: 100%;
    object-fit: cover;
  }
}
.bg_change{
  background-color: unset !important;
  .row{
    background-color: #cc815a !important;
    h2, p{
      color: #fff !important;
    }
    a{
      background: #fff;
      color: #cc815a;
    }
  }
}
#service_bottom {
  background-color: #eee4da;
  h2 {
    color: #cb8565;
  }
  p {
    color: #000000;
    line-height: 1.8rem;
  }
  ul {
    margin: auto;
    display: inline-block;
    padding: 0;
    list-style: none;
    li {
      position: relative;
      &::before {
        position: absolute;
        content: "\2022";
        color: #c68469;
        font-weight: bold;
        display: inline-block;
        font-size: 20px;
        margin-left: -0.8em;
      }
      p {
        color: #c68469;
        font-size: 17px;
        font-weight: 600;
      }
    }
  }
}
#landing_footer {
  // background-color: #eee4da;
  .right_box {
    background-image: linear-gradient(to right, #cc815a 40%, transparent 0%);
    padding: 3.5rem 0 3.5rem 3.5rem;
    img{
      aspect-ratio: 5/3;
      object-fit: cover;
    }
  }
  .footer_left {
    // display: flex;
    align-items: center;
    height: 100%;
  }
  .section_title {
    font-size: 2rem;
    margin-bottom: 2rem;
  }
  p,
  i {
    color: #cd815d;
  }
  .address {
    font-size: 1rem;
    font-weight: 500;
  }
  .phone {
    margin: 8px 0 30px;
  }
  .icon_box {
    i {
      font-size: 1.8rem;
      margin: 0 10px;
      &:first-child {
        margin-left: 0;
      }
    }
  }
  .privacy_para {
    font-size: 1rem;
    font-weight: 300;
    margin-top: 2rem;
  }
}
.services {
  .swiper {
    width: 100%;
    height: 100%;
  }

  .swiper-slide {
    text-align: center;
    font-size: 18px;
    // background: #fff;

    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
  }

  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

@media (max-width: 992px) {
  .landing_header {
    background-color: #ffaba2 !important;
    .navbar {
      color: #fff;
      .nav-item {
        .nav-link {
          color: #fff !important;
        }
      }
    }
  }
  .landing_page {
    #hero_section {
      height: 50vh;
      .hero_outter {  
        max-width: 100%;
        height: 50vh;
      }
    }
  }
}

@media (max-width: 768px) {
  .landing_page {
    #about {
      .about_right {
        .about_right_title {
          h2 {
            width: 100%;
            transform: unset;
          }
        }
      }
    }
    #service_bottom,
    #about_third {
      .section_title {
        text-align: center;
        .bottom {
          margin-left: 10px;
        }
      }
    }
  }
}
@media (max-width: 576px) {
}
