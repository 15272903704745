#landing_page2 {
  // @import url("https://fonts.googleapis.com/css2?family=Marcellus&display=swap");
  @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap');
  // font-family: "Marcellus", serif;
  font-family: "Poppins", sans-serif;

  // background-color: #ded7c6;
  background-color: #dfc5fe;

  h2,
  h3,
  h4,
  h5,
  h6 {
    color: #2c2c2c;
  }
  p {
    color: #513535;
    font-size: 14px;
    font-weight: 400;
  }

  // common
  .l2-btn {
    padding: 12px 20px;
    // background: #8b7551;
    background-color: #a020f0;
    color: #fff;
    font-weight: bold;
  }
  .l2-section-title {
    font-size: 40px;
    font-weight: bolder;
    text-align: center;
    margin-bottom: 45px;
  }
  // .section_title {
  //   font-size: 1.7rem;
  //   font-weight: 600;
  //   text-align: center;
  // }
  .l2-mini-title {
    font-size: 20px;
    margin-bottom: 10px;
  }
  position: relative;
  //header
  .l2-header {
    position: sticky;
    top: 0;
    z-index: 99;
    background-color: transparent;
    .navbar {
      padding: 22px 0;
      transition: 0.5s;
      .navbar-toggler {
        background-color: #fff;
        border-color: #fff;
      }
      .navbar-brand {
        color: #2c2c2c;
      }
      .navbar-nav {
        .nav-item {
          display: flex;
        }
        li {
          a {
            color: #2c2c2c;
            font-size: 16px;
            font-weight: 500;
          }
        }
        .active {
          a {
            border-bottom: 1px solid #b45432;
          }
        }
        .contact {
          display: block;
          background-color: #b45432;
          padding: 0px 20px;
        }
      }
    }
  }
  .navScroll {
    // background-color: #c59e5cc9;
    background-color: #ffffff6b;
    z-index: 99;
    backdrop-filter: blur(10px);
    .navbar {
      padding: 10px 0;
      .navbar-light .navbar-toggler {
        color: #fff;
        &:focus {
          box-shadow: none;
        }
      }
      .navbar-brand {
        color: #000;
      }
      .navbar-nav {
        .active {
          a {
            border-bottom: 1px solid #fff;
          }
        }
        .contact {
          display: block;
          padding: 0 16px;
          background-color: #fff;
          a {
            color: #b45432;
          }
        }
      }
    }
  }

  // banner
  #l2-hero {
    height: 700px;
    // margin-bottom: 90px;
    .l2-hero-left {
      height: 700px;
      display: flex;
      align-items: center;
      .l2-hero-content {
        h2 {
          font-size: 2rem;
          font-weight: 500;
        }
        p {
          // max-width: 300px;
          font-weight: 400;
          font-size: 14px;
        }
      }
    }
    .l2-right-content {
      height: 700px;
      align-items: center;
      position: relative;
      .l2-hero-big-img {
        width: 100%;
        height: 600px;
        object-fit: cover;
      }
      .l2-hero-mini-img {
        height: 500px;
        width: 100%;
        object-fit: cover;
      }
      .pastels-nails {
        position: absolute;
        padding: 20px;
        max-width: 300px;
        background-color: #c59e5c7a;
        left: 35%;
        bottom: 22%;
        border-radius: 5px;
        backdrop-filter: blur(10px);
        p,
        h6 {
          color: #000;
        }
        h6 {
          font-weight: bold;
        }
      }
    }
  }
  #l2-services {
    margin: 90px 0;
    .before-title {
      height: 21px;
      margin-right: 12px;
      width: 18px;
      background-color: #ab9062;
    }
    // background-color: #fff8f5;
    .services_inner {
      // padding: 2.5rem 0 4rem;
      .single_service {
        // background-color: #fff8f5;
        p {
          text-align: start;
          margin-bottom: 13px;
          font-weight: 500;
          font-size: 16px;
        }
        img {
          border-radius: 15px;
          aspect-ratio: 15/10;
        }
      }
      .service_bottom {
        margin-top: 2.5rem;
        .next_prev_icons {
          svg {
            color: #000;
            font-size: 20px;
            cursor: pointer;
          }
          #prev_btn {
            margin-right: 20px;
          }
        }
        .swiper-button-disabled svg {
          color: gainsboro;
        }
      }
    }
  }
  .page_btn {
    font-size: 1rem;
    // background-color: #ffaba2;
    background-color: #a020f0;
    color: #fff;
    border-radius: 25px;
    padding: 7px 20px;
    display: inline-block;
  }
  #l2-service_advice {
    margin-bottom: 90px;
    img {
      width: 100%;
    }
  }

  #l2-experience {
    margin-bottom: 90px;
    img {
      width: 100%;
    }
    button {
      border: none;
      background: #8b7551;
      color: white;
      padding: 10px 25px;
      margin-top: 10px;
    }
  }

  #l2-provide_services {
    margin-bottom: 90px;
    img {
      width: 90%;
    }
    .star {
      margin-right: 18px;
      svg {
        margin-bottom: 4px;
        font-size: 17px;
        color: #8b7551;
      }
    }
  }

  #l2-faq {
    margin-bottom: 90px;
    img {
      width: 100%;
      aspect-ratio: 1/1;
    }
    .ant-collapse {
      background-color: transparent;
      .ant-collapse-header {
        padding: 12px 0;
        .ant-collapse-header-text {
          font-size: 20px;
        }
      }
      .ant-collapse-item {
        border-bottom: 2px solid #78717129;
        .ant-collapse-content,
        .ant-collapse-content-active {
          background-color: transparent;
        }
        .ant-collapse-content-box {
          padding: 0 16px 16px 0;
        }
        &:first-child {
          border-top: 2px solid #78717129;
        }
      }
    }
  }

  // #l2-footer {
  //   padding: 90px 0;
  //   ul {
  //     margin-bottom: 0;
  //   }
  //   background: #62533a;
  //   h3 {
  //     color: white;
  //     padding-bottom: 20px;
  //   }
  //   .l2-mini-title {
  //     color: #fff;
  //   }
  //   p {
  //     color: lightgrey;
  //   }
  //   .l2-footer-icon {
  //     font-size: 25px;
  //     a {
  //       color: #fff;
  //     }
  //   }
  //   ul {
  //     padding-left: 0px;
  //     li {
  //       list-style: none;
  //       text-decoration: underline;
  //       padding: 10px 0;
  //       a {
  //         color: lightgrey;
  //         font-size: 16px;
  //       }
  //     }
  //   }
  // }
  .l2-testimonials {
    // background-color: #fff8f5;
    padding: 3rem 0;
    .section_title {
      margin-bottom: 2.5rem;
      margin-top: 2.5rem;
    }

    .single_testimonial:nth-child(odd) {
      .review_text_box {
        background: #a269e4;
        position: relative;
        p {
          color: #fff;
        }
        .quota {
          position: absolute;
          height: 40px;
          width: 40px;
          background: #a269e4;
          bottom: -17px;
          rotate: 45deg;
          left: 24%;
          border-radius: 5px;
        }
      }
    }
    .single_testimonial {
      .review_text_box {
        background: #fff;
        padding: 40px;
        // height: 146px;
        border-radius: 10px;
      }
      .user_identity {
        margin-top: 20px;
        display: flex;
        align-items: center;
        p {
          margin-bottom: 0;
        }
        p.name {
          font-weight: 600;
        }
        img {
          width: 45px;
          height: 45px;
          object-fit: cover;
          border-radius: 50%;
          margin-right: 10px;
        }
        .user-rating {
          font-size: 12px;
          .ant-rate-star:not(:last-child) {
            margin-right: 3px;
          }
          .ant-rate-star-full{
            svg{
              fill: #000;
            }
          }
          .ant-rate-star-zero {
            .anticon-star {
              color: #a3a1a1;
            }
          }
        }
      }
    }
    .next_prev_icons {
      position: relative;
      .next_prev_icons_inner {
        z-index: 1;
        position: absolute;
        bottom: 5px;
        right: 0;
        svg {
          color: #000;
          font-size: 20px;
          cursor: pointer;
        }
        #prev_btn {
          margin-right: 20px;
        }
        .swiper-button-disabled svg {
          color: gainsboro;
        }
      }
    }
  }

  // get in touch
  .l2-contact {
    padding: 90px 0;
    .section_title {
      margin-bottom: 2.5rem;
      // justify-content: end;
      // h2{
      //   max-width: 66%;
      // }
    }
    .contact-img {
      img {
        width: 100%;
        object-fit: cover;
        border-radius: 5px;
      }
    }
    .input_form {
      text-align: end;
      input {
        // min-width: 320px;
        width: 95%;
        border: none;
        border-radius: 25px;
        padding: 16px 25px;
        margin-bottom: 2rem;
      }
      a {
        // min-width: 320px;
        width: 95%;
        margin: auto;
        display: block;
        text-align: center;
        border-radius: 25px;
        border: none;
        padding: 12px 25px;
        background-color: #a020f0;
        color: #fff;
        font-weight: 600;
      }
    }
  }

  // footer
  .landing2_footer {
    margin-top: 2.5rem;
    .upper_section {
      h5 {
        margin-bottom: 1rem;
      }
      p {
        margin-bottom: 0.5rem;
      }
      .footer_logo {
        text-align: end;
        img {
          width: 150px;
        }
      }
    }
    .bottom_section {
      .copyright {
        font-weight: 500;
        font-size: 13px;
      }
      .policy {
        p {
          font-weight: 500;
          font-size: 13px;
        }
      }
    }
  }

  @media (max-width: 992px) {
    .l2-section-title {
      font-size: 45px;
      font-weight: bolder;
      text-align: center;
      margin-bottom: 30px;
    }
    #l2-header {
      .navbar {
        padding: 10px 0;
        .navbar-light .navbar-toggler {
          color: #fff;
          &:focus {
            border: none;
          }
        }
        .navbar-brand {
          color: #fff;
        }
        .navbar-nav {
          .active {
            a {
              border-bottom: 1px solid #fff;
            }
          }
        }
      }
    }
    #l2-hero {
      height: 400px;
      .l2-hero-left {
        height: 400px;
      }
      background-image: url("/img/landing2/hero-first.jpg");
      background-size: cover;
      background-repeat: no-repeat;
    }
  }
  @media (max-width: 576px) {
    .l2-testimonials {
      .user_identity {
        margin-top: 35px !important;
      }
    }
  }
}
